import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-internal-memo',
  templateUrl: './internal-memo.component.html',
  styleUrls: ['./internal-memo.component.scss']
})
export class InternalMemoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
