// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  // host: "https://docstreamapi.azurewebsites.net/",
  // auth_url: "https://docstreamapi.azurewebsites.net/api/auth/int/",
  // base_url: "https://docstreamapi.azurewebsites.net/api/internal/",

  // host: "http://localhost:54656/",
  // auth_url: "http://localhost:54656/api/auth/int/",
  // base_url: "http://localhost:54656/api/internal/",
  // g2g_url: "http://localhost:54656/api/gtog/",

  host: "https://policedocstream.azurewebsites.net/",
  auth_url: "https://policedocstream.azurewebsites.net/api/auth/int/",
  base_url: "https://policedocstream.azurewebsites.net/api/internal/",
  g2g_url: "https://policedocstream.azurewebsites.net/api/gtog/",

  // host: "http://localhost:61568/",
  // auth_url: "http://localhost:61568/api/auth/int/",
  // base_url: "http://localhost:61568/api/internal/",
  // g2g_url: "http://localhost:61568/api/gtog/",

  // host: 'http://localhost:54656/',
  // // host: "http://localhost:54656/",
  // auth_url: 'http://localhost:54656/api/auth/int/',
  // base_url: 'http://localhost:54656/api/internal/',
  // g2g_url: 'http://localhost:54656/api/gtog/',
  // host: 'http://localhost:54656/',
  // auth_url: 'http://localhost:54656/api/auth/int/',
  // base_url: 'http://localhost:54656/api/internal/',
  // g2g_url: 'http://localhost:54656/api/gtog/',
  // auth_url: "http://localhost:54656/api/auth/int/",
  // base_url: "http://localhost:54656/api/internal/",
  // g2g_url: "http://localhost:54656/api/gtog/",
  // pdf_service: "https://docstreampdfviewerservice20191005115858.azurewebsites.net/api/pdfviewer"
  pdf_service: "http://localhost:38690/api/pdfviewer",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
