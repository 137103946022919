import { CacheRequest } from "./../../models/cache";
import { Helpers } from "./../../helpers";
import { User } from "./../../models/user";
import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpRequest,
  HttpInterceptor,
  HttpHandler,
  HttpEvent,
  HttpResponse,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { of } from "rxjs";
import { map, timeout, catchError } from "rxjs/operators";
import { Router } from "@angular/router";
import { MatSnackBar } from "@angular/material";
import { RequestCache } from "./request-cache";
import { startWith, tap } from "rxjs/operators";
import { environment } from "./../../../environments/environment";

@Injectable()
export class NetworkService implements HttpInterceptor {
  link: string = environment.base_url;
  defaultLink: string = environment.host;
  g2glink: string = environment.g2g_url;

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    if (NetworkService.cacheRequest == CacheRequest.Cache) {
      const cachedResponse = this.cache.get(req);
      return cachedResponse
        ? of(cachedResponse)
        : this.sendRequestFinale(req, next, this.cache);
    } else {
      return this.sendRequestFinale(req, next, this.cache);
    }
  }

  public static DASHBOARD = "dashboard";
  public static PREV_CORESS = "dashboard";
  public static SUBMIT_FILES = "submission";
  public static SUBMIT_FILES_G2G = "gov-gov-submission";
  public static GET_MINISTRIES = "get-ministries";
  public static FETCH_USERS_AND_DEPT_FOR_MEETING = "get-ministry-users";
  public static FETCH_USERS_AND_DEPT_FOR_LEAVE = "get-dept-users";
  public static GET_SUB_DEPARTMENT_USERS = "get-sub-department-users";
  public static GET_DEPARTMENT_USERS = "get-department-users";
  public static GET_MINISTRY_WIDE_USERS = "get-ministry-wide-users";
  public static GET_DEPARTMENT_USERS_2 = "get-department-users-2";
  public static GET_DEPARTMENT_USERS_PLAIN = "get-department-users-plain";
  public static FETCH_LEAVE_REQUESTS = "leave/view-requests";
  public static FETCH_DEPT_HEAD_AND_HR_HEAD_FOR_LEAVE = "get-dept-hr-heads";
  public static FETCH_LEAVE_SUMMARY = "leave/leave-summary";
  public static FETCH_LEAVE_APPLICATIONS = "leave/my-applications";
  public static FETCH_LEAVE_DETAIL = "leave/detail";
  public static APPROVE_LEAVE_REQUEST = "leave/review-application";
  public static REJECT_LEAVE_REQUEST = "leave/review-application";
  public static CREATE_LEAVE_APPLICATION = "leave/create-leave";
  public static GET_MEETING_INVITES = "get-events-invite";
  public static ACCEPT_INVITE = "accept-event";
  public static REJECT_INVITE = "reject-event";
  public static CANCEL_EVENT = "delete-event";
  public static SUBMIT_ARCHIVES = "fileManagerModule/archive-files";
  public static INTERNAL_FILE_SUBMISSION = "internal-file-submission";
  public static GET_FILE_LIST = "get-file-list";
  public static GET_INT_DOC_LIST = "get-int-docs";
  public static GET_DEPT_ARCHIVE_LIST =
    "fileManagerModule/fetch-department-archive";
  public static GET_USER_ARCHIVE_LIST = "fileManagerModule/fetch-user-archive";
  public static GET_RECIEVED_ARCHIVE_LIST =
    "fileManagerModule/fetch-recieved-archive";
  public static GET_ARCHIVE_TASKS_LIST =
    "fileManagerModule/fetch-routed-archive-task";
  public static GET_MINISTRY_DEPT = "get-ministry-department";
  public static GET_USER_POSITIONS = "get-user-positions";
  public static GET_CONFY_LEVELS = "get-confy-levels";
  public static GET_STORAGE_PATTERNS = "get-storage-patterns";
  public static TRACK_FILE = "trackfile";
  public static TRACK_FILE_G2G = "gov-gov-track";
  public static FETCH_DETAILS = "details";
  public static FETCH_ARCHIVE_FILES_DETAILS = "fetch-user-archived-docs";
  public static RESEND_TOKEN = "resend-token";
  public static ACTIVATE_ACCOUNT = "activate-account";
  public static UPDATE_PROFILE = "update_user_details";
  public static GET_SUB_DEPARTMENTS = "get-sub-departments";
  public static GET_TEAM_MEMBERS = "get-team-members";
  public static GET_ROUTEES = "get-routees";
  public static GET_SUB_DETAILS = "get-submission-details";
  public static GET_SUB_COMMENTS = "get-submission-comments";
  public static ACCEPT_SUBMISSION = "primary-treats/accept-submission";
  public static SEC_ACCEPT_SUBMISSION = "secondary-treats/accept-submission";
  public static REJECT_SUBMISSION = "primary-treats/reject-correspondence";
  public static RECALL_SUBMISSION = "recall_submission";
  public static SEC_REJECT_SUBMISSION = "secondary-treats/reject-submission";
  public static ROUTE_SUBMISSION = "primary-treats/route-correspondence";
  public static CANCEL_ROUTE_SUBMISSION = "primary-treats/cancel-route";
  public static REVOKE_ACCESS = "revoke-access";
  public static ROUTE_TASK = "primary-treats/route-task";
  public static GET_SUB_FINALIZE_MEMBERS = "get-sub-finalize-members";
  public static FINALIZE_CORRESPONDENCE =
    "primary-treats/return-correspondence-complete";
  public static SEC_FINALIZE_CORRESPONDENCE =
    "secondary-treats/return-submission-complete";
  public static TEAM_ADD_MEMBERS = "team/add-members";
  public static TEAM_REMOVE_MEMBERS = "team/remove-members";
  public static TEAM_REJECT_REQUEST = "team/reject-request";
  public static TEAM_ACCEPT_REQUEST = "team/accept-team-request";
  public static LEAVE_TEAM = "team/leave-team";
  public static SEND_COMMENT = "send-submission-comment";
  public static FILE_CATCH = "file-catch";
  public static CREATE_MEMO = "create-memo";
  public static CREATE_MINUTE = "create-minute";
  public static CREATE_CIRCULAR = "create-circular";
  public static SIG_INIT = "sigcom/init";
  public static CLEAR_NOTIFICATION = "clear-notification";
  public static GET_CHATS = "get-chats";
  public static GET_CHAT_MESSAGES = "get-chat-messages";
  public static GET_DEPARTMENTS = "get-departments";
  public static GET_MEMOS = "get-memos";
  public static GET_APPROVED_MEMOS = "get-approved-memos";
  public static GET_ALL_DOCS = "get-int-docs";
  public static GET_MINUTES = "get-minutes";
  public static GET_CIRCULARS = "get-circulars";
  public static GET_REWARDS = "rewards-page";
  public static CHANGE_PASSWORD = "int/change-password";
  public static GET_ARCH_FILE = "fileManagerModule/view-archived-files-details";
  public static CREATE_NEW_EVENT = "create-event";
  public static GET_EVENT = "get-events";
  public static GET_EVENT_BY_ID = "get-event-by-id";

  public static TREAT_FILE = "treat-file";
  public static ROUTE_MEMO = "route-memo";
  public static ROUTE_MINUTE = "route-minute";
  public static ROUTE_CIRCULAR = "route-circular";

  public static GET_DOC_DETAILS = "get-int-docs-details";
  public static GET_INST_USERS = "get-institution-users";
  public static CONFIRM_SUB_TOKEN = "confirm-token";

  public static MEMO_TREAT = "memo/treat";
  public static CIRCULAR_TREAT = "review-circular";
  public static DELEGATE_TASK = "delegate-task";
  public static GET_TASKS = "get-tasks";

  public static PULL_ANNOTATIONS = "pull-annotations";
  public static PUSH_ANNOTATIONS = "push-annotations";
  public static SYNC_ANNOTATIONS = "sync-annotations";
  public static GET_ARFile_DEPARTMENTS = "get-archive-doc-depts";
  public static GET_ARFile_DEPARTMENT_USERS = "get-archive-doc-dept-users";
  public static GET_ARCH_COMMENTS = "get-archive-doc-comments";
  public static ROUTE_ARCH = "fileManagerModule/route-archived-document";
  public static DOC_SEARCH = "document-search";

  public static FINALIZE_ARCH = "fileManagerModule/finalize-archived-document";

  public static GET_INSTITUTIONS = "get-institutions";
  public static GET_INSTITUTION_DEPARTMENTS = "get-institution-departments";
  public static CREATE_GROUP_CHAT = "create-group-chat";
  public static TOGGLE_GROUP_ADMIN = "toggle-group-admin";
  public static ADD_GROUP_CHAT_MEMBERS = "add-group-chat-members";
  public static REMOVE_GROUP_CHAT_MEMBER = "remove-group-chat-member";
  public static EXIT_GROUP_CHAT = "exit-group-chat";
  public static UPLOAD_CHAT_ATTACHMENT = "upload-chat-attachment";
  public static CHANGE_GROUP_NAME = "change-group-name";

  public static CREATE_NEW_FOLDER = "api/FileJacket/CreateFolder";
  public static GET_ALL_SUBMISSION = "api/FileJacket/AllSubmissions";
  public static GET_ALL_FOLDERS = "api/FileJacket/GetFolders";
  public static ADD_TO_FOLDER = "api/FileJacket/AddToFolder";
  public static GETFOLDERSUBMISSIONS = "api/FileJacket/GetSubmissionsInFolder";

  public static GETALLACCOUNTOPENINGREQUESTS = "api/jaizcustomer/individual";
  public static GETALLUNTREATEDREQUESTS =
    "api/account-opening-review/untreated-forms";
  public static GETALLPENDINGREQUESTS =
    "api/account-opening-review/pending-forms";
  public static GETALLTREATEDREQUESTS =
    "api/account-opening-review/treated-forms";
  public static GETSINGLEACCOUNTOPENINGREQUEST =
    "api/account-opening-review/get-single-form";
  public static GETACCOUNTOPENINGREQUESTROUTEES =
    "api/account-opening-review/get-routee-users";
  public static ROUTEACCOUNTOPENINGFORM = "api/account-opening-review/comment";
  public static GETACCOUNTOPENINGCOMMENTS =
    "api/account-opening-review/get-comments";
  public static APPROVEACCOUNTOPENINGFORM =
    "api/account-opening-review/approve-form";
  public static DECLINEACCOUNTOPENINGFORM =
    "api/account-opening-review/decline-form";

  public static GETALLECARBINETFOLDERSBYSTATUS =
    "api/filejacket/foldersbystatus";
  public static GET_ALL_DIVISION_ECARBINET_MEMOS_FOLDERS =
    "api/filejacket/GetMemosInDivision";
  public static GET_ALL_DIVISION_ECARBINET_FILES_FOLDERS =
    "api/filejacket/GetFilesInDivision";
  public static GETALLECARBINETMEMOFOLDERS =
    "api/filejacket/get-folders-for-memo";
  public static GETSINGLEECARBINETMEMOFOLDERS =
    "api/filejacket/GetMemosInFolder";
  public static GETALLECARBINETFOLDERSACTIVEORFINALIZED =
    "api/filejacket/Active_Finalized";
  public static ARCHIVEANECARBINETFOLDER = "api/filejacket/archive-folder";
  public static SENDMESSAGEANDFILESTOCUSTOMER =
    "api/customercommunication/send-message-to-customer";
  public static GETALLINCOMINGMAIL = "api/IncomingMail/all";
  public static GETALLEXTERNALINCOMINGMAIL = "api/IncomingMail/pending";
  public static GET_ALL_INCOMING_MAILS_SUBMISSION = "api/IncomingMail/pending";
  public static GET_SINGLE_INCOMING_MAILS_SUBMISSION = "api/IncomingMail/one";
  public static COMPLETE_INCOMING_MAILS_SUBMISSION =
    "api/IncomingMail/registry-update";
  public static CREATE_INCOMING_MAILS_SUBMISSION =
    "api/IncomingMail/registry-create";
  public static ADD_FILES_TO_INCOMING_MAILS_SUBMISSION =
    "api/IncomingMail/add-files";
  public static GET_ALL_STAFF_UNTREATED_INCOMING_MAILS =
    "api/IncomingMail/get-staff-untreated";
  public static GET_ALL_STAFF_TREATED_INCOMING_MAILS =
    "api/IncomingMail/get-staff-completed";
  public static GET_ALL_STAFF_PENDING_INCOMING_MAILS =
    "api/IncomingMail/get-staff-pending";
  public static GET_ALL_STAFF_CLOSED_INCOMING_MAILS =
    "api/IncomingMail/get-staff-closed";
  public static CHANGE_STAFF_FILE_STATUS = "change-staff-file-status";
  public static CHANGE_MEMO_FILE_STATUS = "change-memo-status";
  public static CHANGE_STAFF_WIP_FILE_STATUS = "file-work-in-progress";
  public static CHANGE_FILE_STATUS = "change-file-status";
  public static REJECT_SUBMISSION_BY_STAFF_STATUS = "reject-correspondence";
  public static FETCH_AWAITING_MEMOS = "get-pending-memos";
  public static ADD_MEMO_TO_FOLDER = "update-memo";
  public static RETURN_MEMO_TO_SENDER = "return-memo-to-sender";
  public static REVIEW_MEMO_AND_CHANGE_RECIPIENTS = "review-memo-change-to";
  public static FINAL_MEMO_APPROVER_NO_TOKEN = "approve-memo";
  public static FINAL_MEMO_APPROVER_WITH_TOKEN = "confirm-memo-token-approval";
  public static REVIEW_MEMO_ONLY = "review-memo";
  public static GENERATE_MEMO_APPROVAL_TOKEN = "generate-approve-memo-token";
  public static CHANGE_PROFILE_SIGNATURE = "api/Internal/int/upload-signature";
  public static GET_ALL_FOLDERS_IN_ARCHIVE = "api/ArchiveFolders";
  public static ADD_METADATA_TO_FOLDERS_IN_ARCHIVE =
    "api/ArchiveFolders/Metadata/Add";
  public static EDIT_METADATA_TO_FOLDERS_IN_ARCHIVE =
    "api/ArchiveFolders/Metadata/Edit";
  public static DELETE_METADATA_TO_FOLDERS_IN_ARCHIVE =
    "api/ArchiveFolders/Metadata/Delete";
  public static GET_METADATA_IN_FOLDERS_IN_ARCHIVE =
    "api/ArchiveFolders/Metadata";
  public static UPLOAD_FILES_IN_FOLDERS_IN_ARCHIVE =
    "api/ArchiveFolders/UploadFiles";

  headers = new HttpHeaders();
  //link = "https://790d56d3-a1b4-416e-92a9-4c71a8d027b6.mock.pstmn.io/api/ext/";

  static cacheRequest: CacheRequest = CacheRequest.Default;

  data = {};

  constructor(
    private http: HttpClient,
    private router: Router,
    private cache: RequestCache
  ) {
    // if(localStorage.getItem('sm_user')){
    //     this.headers
    //     .set("Content-Type", "application/x-www-form-urlencoded")
    //     .set("Authorization", "Bearer " + new User().parseUser().token);
    //   }
  }

  sendRequestFinale(
    req: HttpRequest<any>,
    next: HttpHandler,
    cache: RequestCache
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap((event) => {
        if (event instanceof HttpResponse) {
          if (
            NetworkService.cacheRequest == CacheRequest.Cache ||
            NetworkService.cacheRequest == CacheRequest.Reload
          ) {
            cache.put(req, event);
          }
        }
      })
    );
  }

  sendPlainRequest(datax, url, cacheReq?: CacheRequest) {
    let headers = new HttpHeaders().set(
      "Content-Type",
      "application/json;charset=UTF-8"
    );
    let fi = this.jsonToURLEncoded(datax);
    return this.http.post(url, datax, { headers }).pipe(
      timeout(300000),
      map((response) => {
        if (response && response["error"] && response["typee"] == "333") {
          localStorage.removeItem(Helpers.STORAGE_TAG);
          this.router.navigate(["/login"]);
        }
        return response;
      })
    );
  }
  sendFileJacketRequest(datax, type, cacheReq?: CacheRequest) {
    this.checkAuth();
    NetworkService.cacheRequest = cacheReq ? cacheReq : CacheRequest.Default;
    let headers = new HttpHeaders()
      .set("Content-Type", "application/x-www-form-urlencoded")
      .set("Authorization", "Bearer " + User.getCurrentUser().token);
    headers.append("observe", "response");
    let body = JSON.stringify(datax);
    var d = new Date();
    var off = d.getTimezoneOffset();
    let fi = this.jsonToURLEncoded(datax);
    return this.http.post(this.defaultLink + type, fi, { headers }).pipe(
      timeout(300000),
      map((response) => {
        if (response && response["error"] && response["typee"] == "333") {
          localStorage.removeItem(Helpers.STORAGE_TAG);
          this.router.navigate(["/login"]);
        }
        return response;
      }),
      catchError((err: HttpErrorResponse) => {
        if (err.status == 401) {
          //unauthorised
          localStorage.removeItem(Helpers.STORAGE_TAG);
          this.router.navigate(["/login"]);
        }
        return Observable.throw(err);
      })
    );
  }

  sendRequest(datax, type, cacheReq?: CacheRequest) {
    this.checkAuth();
    NetworkService.cacheRequest = cacheReq ? cacheReq : CacheRequest.Default;
    let headers = new HttpHeaders()
      .set("Content-Type", "application/x-www-form-urlencoded")
      .set("Authorization", "Bearer " + User.getCurrentUser().token);
    headers.append("observe", "response");
    let body = JSON.stringify(datax);
    var d = new Date();
    var off = d.getTimezoneOffset();
    let fi = this.jsonToURLEncoded(datax);
    return this.http.post(this.link + type, fi, { headers }).pipe(
      timeout(300000),
      map((response) => {
        if (response && response["error"] && response["typee"] == "333") {
          localStorage.removeItem(Helpers.STORAGE_TAG);
          this.router.navigate(["/login"]);
        }
        return response;
      }),
      catchError((err: HttpErrorResponse) => {
        if (err.status == 401) {
          //unauthorised
          localStorage.removeItem(Helpers.STORAGE_TAG);
          this.router.navigate(["/login"]);
        }
        return Observable.throw(err);
      })
    );
  }

  sendRequestArchive(datax, type, cacheReq?: CacheRequest) {
    this.checkAuth();
    NetworkService.cacheRequest = cacheReq ? cacheReq : CacheRequest.Default;
    let headers = new HttpHeaders()
      .set("Content-Type", "application/x-www-form-urlencoded")
      .set("Authorization", "Bearer " + User.getCurrentUser().token);
    headers.append("observe", "response");
    let body = JSON.stringify(datax);
    var d = new Date();
    var off = d.getTimezoneOffset();
    let fi = this.jsonToURLEncoded(datax);
    return this.http.post(environment.host + type, fi, { headers }).pipe(
      timeout(300000),
      map((response) => {
        if (response && response["error"] && response["typee"] == "333") {
          localStorage.removeItem(Helpers.STORAGE_TAG);
          this.router.navigate(["/login"]);
        }
        return response;
      }),
      catchError((err: HttpErrorResponse) => {
        if (err.status == 401) {
          //unauthorised
          localStorage.removeItem(Helpers.STORAGE_TAG);
          this.router.navigate(["/login"]);
        }
        return Observable.throw(err);
      })
    );
  }

  sendRequestUpdated(datax, type, cacheReq?: CacheRequest) {
    this.checkAuth();
    NetworkService.cacheRequest = cacheReq ? cacheReq : CacheRequest.Default;
    let headers = new HttpHeaders()
      .set("Content-Type", "application/x-www-form-urlencoded")
      .set("Authorization", "Bearer " + User.getCurrentUser().token);
    headers.append("observe", "response");
    let body = JSON.stringify(datax);
    var d = new Date();
    var off = d.getTimezoneOffset();
    let fi = this.jsonToURLEncoded(datax);
    return this.http.post(this.defaultLink + type, fi, { headers }).pipe(
      timeout(300000),
      map((response) => {
        if (response && response["error"] && response["typee"] == "333") {
          localStorage.removeItem(Helpers.STORAGE_TAG);
          this.router.navigate(["/login"]);
        }
        return response;
      }),
      catchError((err: HttpErrorResponse) => {
        if (err.status == 401) {
          //unauthorised
          localStorage.removeItem(Helpers.STORAGE_TAG);
          this.router.navigate(["/login"]);
        }
        return Observable.throw(err);
      })
    );
  }

  sendRequestG2G(datax, type, cacheReq?: CacheRequest) {
    // if(cache){
    //     this.cacheRequest = true;
    // }else{
    //     this.cacheRequest = false;
    // }
    this.checkAuth();
    NetworkService.cacheRequest = cacheReq ? cacheReq : CacheRequest.Default;
    let headers = new HttpHeaders()
      .set("Content-Type", "application/x-www-form-urlencoded")
      .set("Authorization", "Bearer " + User.getCurrentUser().token);
    headers.append("observe", "response");
    let body = JSON.stringify(datax);
    var d = new Date();
    var off = d.getTimezoneOffset();
    //datax['uid'] = "";
    //datax['timeoffset'] = off;
    //console.log(datax);
    let fi = this.jsonToURLEncoded(datax);
    return this.http.post(this.g2glink + type, fi, { headers }).pipe(
      timeout(300000),
      map((response) => {
        if (response && response["error"] && response["typee"] == "333") {
          localStorage.removeItem(Helpers.STORAGE_TAG);
          this.router.navigate(["/login"]);
        }
        return response;
      }),
      catchError((err: HttpErrorResponse) => {
        if (err.status == 401) {
          //unauthorised
          localStorage.removeItem(Helpers.STORAGE_TAG);
          this.router.navigate(["/login"]);
        }
        return Observable.throw(err);
      })
    );
  }
  pagedRequest(datax, type, cacheReq?: CacheRequest) {
    this.checkAuth();
    NetworkService.cacheRequest = cacheReq ? cacheReq : CacheRequest.Default;
    let headers = new HttpHeaders()
      .set("Content-Type", "application/x-www-form-urlencoded")
      .set("Authorization", "Bearer " + User.getCurrentUser().token);
    let body = JSON.stringify(datax);
    var d = new Date();
    var off = d.getTimezoneOffset();
    let fi = this.jsonToURLEncoded(datax);
    return this.http
      .post(this.link + type, fi, { headers, observe: "response" })
      .pipe(
        timeout(300000),
        map((response) => {
          if (response && response["error"] && response["typee"] == "333") {
            localStorage.removeItem(Helpers.STORAGE_TAG);
            this.router.navigate(["/login"]);
          }
          return response;
        }),
        catchError((err: HttpErrorResponse) => {
          if (err.status == 401) {
            localStorage.removeItem(Helpers.STORAGE_TAG);
            this.router.navigate(["/login"]);
          }
          return Observable.throw(err);
        })
      );
  }

  fileUpload(fileItem: File, type: string, extraData?: object): any {
    this.checkAuth();
    const formData: FormData = new FormData();
    let headers = new HttpHeaders().set(
      "Authorization",
      "Bearer " + User.getCurrentUser().token
    );
    formData.append("fileItem", fileItem, fileItem.name);
    if (extraData) {
      for (let key in extraData) {
        formData.append(key, extraData[key]);
      }
    }

    const req = new HttpRequest("POST", this.link + type, formData, {
      headers: headers,
      reportProgress: true, // for progress data
    });
    // return this.http.request(req).pipe(timeout(30000), map(response => {
    //     if (response && response['error'] && response['typee'] == '333') {
    //         localStorage.removeItem('sm_user');
    //         this.router.navigate(['/login']);
    //     }
    //     return response;
    // }));
    return this.http.request(req);
  }

  filesUpload(
    fileItems: Array<{ key: string; file: File }>,
    type: string,
    extraData?: object
  ): any {
    this.checkAuth();

    const formData: FormData = new FormData();
    let headers = new HttpHeaders().set(
      "Authorization",
      "Bearer " + User.getCurrentUser().token
    );
    fileItems.forEach((element) => {
      formData.append(element.key, element.file, element.file.name);
    });

    if (extraData) {
      for (let key in extraData) {
        formData.append(key, extraData[key]);
      }
    }

    const req = new HttpRequest("POST", this.link + type, formData, {
      headers: headers,
      reportProgress: true, // for progress data
    });
    // return this.http.request(req).pipe(timeout(30000), map(response => {
    //     if (response && response['error'] && response['typee'] == '333') {
    //         localStorage.removeItem('sm_user');
    //         this.router.navigate(['/login']);
    //     }
    //     return response;
    // }));
    return this.http.request(req);
  }

  filesUploadKey(
    fileItems: Array<File>,
    type: string,
    extraData?: object,
    key: string = "files"
  ): any {
    this.checkAuth();
    const formData: FormData = new FormData();
    let headers = new HttpHeaders()
      .set("Authorization", "Bearer " + User.getCurrentUser().token)
      .set("Access-Control-Allow-Origin", "*");
    fileItems.forEach((file) => {
      formData.append(key, file, file.name);
    });

    if (extraData) {
      for (let key in extraData) {
        formData.append(key, extraData[key]);
      }
    }
    const req = new HttpRequest("POST", this.link + type, formData, {
      headers: headers,
      reportProgress: true, // for progress data
    });
    return this.http.request(req);
  }
  // filesUploadWithKey(fileItems:Array<File>, key: string, type: string, extraData?:object):any{
  //     const formData: FormData = new FormData();
  //     let headers = new HttpHeaders()
  //         .set("Authorization", "Bearer " + User.getCurrentUser().token).set("Access-Control-Allow-Origin", "*");
  //     fileItems.forEach(file => {
  //         formData.append(key, file, file.name);
  //     });

  //     if (extraData) {
  //       for(let key in extraData){
  //         formData.append(key, extraData[key])
  //       }
  //     }
  //     const req = new HttpRequest('POST', this.link + type, formData, {
  //       headers: headers,
  //       reportProgress: true // for progress data
  //     });
  //     return this.http.request(req);
  // }

  archivesUploadNoKey(
    fileItems: Array<File>,
    type: string,
    extraData?: object
  ): any {
    //   debugger;
    this.checkAuth();
    const formData: FormData = new FormData();
    let headers = new HttpHeaders()
      .set("Authorization", "Bearer " + User.getCurrentUser().token)
      .set("Access-Control-Allow-Origin", "*");
    fileItems.forEach((file) => {
      formData.append("files", file, file.name);
    });

    if (extraData) {
      for (let key in extraData) {
        formData.append(key, extraData[key]);
      }
    }

    return this.http.post(this.link + type, formData, { headers });
  }

  memoCircularMinutesUploadNoKey(
    fileItems: Array<File>,
    type: string,
    extraData?: object
  ): any {
    //   debugger;
    this.checkAuth();
    const formData: FormData = new FormData();
    let headers = new HttpHeaders()
      .set("Authorization", "Bearer " + User.getCurrentUser().token)
      .set("Access-Control-Allow-Origin", "*");
    fileItems.forEach((file) => {
      formData.append("SupportingDocuments", file, file.name);
    });

    if (extraData) {
      for (let key in extraData) {
        formData.append(key, extraData[key]);
      }
    }

    return this.http.post(this.link + type, formData, { headers });
  }

  incomingMailsUploadNoKey(
    fileItems: Array<File>,
    type: string,
    extraData?: object
  ): any {
    //   debugger;
    this.checkAuth();
    const formData: FormData = new FormData();
    let headers = new HttpHeaders()
      .set("Authorization", "Bearer " + User.getCurrentUser().token)
      .set("Access-Control-Allow-Origin", "*");
    fileItems.forEach((file) => {
      formData.append("Files", file, file.name);
    });

    if (extraData) {
      for (let key in extraData) {
        formData.append(key, extraData[key]);
      }
    }

    return this.http.post(this.defaultLink + type, formData, { headers });
  }

  signatureUploadNoKey(
    fileItems: Array<File>,
    type: string,
    extraData?: object
  ): any {
    //   debugger;
    this.checkAuth();
    const formData: FormData = new FormData();
    let headers = new HttpHeaders()
      .set("Authorization", "Bearer " + User.getCurrentUser().token)
      .set("Access-Control-Allow-Origin", "*");
    fileItems.forEach((file) => {
      formData.append("Signature", file, file.name);
    });

    if (extraData) {
      for (let key in extraData) {
        formData.append(key, extraData[key]);
      }
    }

    return this.http.post(this.link + type, formData, { headers });
  }

  meetingsUploadNoKey(
    fileItems: Array<File>,
    type: string,
    extraData?: object
  ): any {
    //   debugger;
    this.checkAuth();
    const formData: FormData = new FormData();
    let headers = new HttpHeaders()
      .set("Authorization", "Bearer " + User.getCurrentUser().token)
      .set("Access-Control-Allow-Origin", "*");
    fileItems.forEach((file) => {
      formData.append("Attachments", file, file.name);
    });

    if (extraData) {
      for (let key in extraData) {
        formData.append(key, extraData[key]);
      }
    }

    return this.http.post(this.link + type, formData, { headers });
  }

  filesUploadNoKey(
    fileItems: Array<File>,
    type: string,
    extraData?: object
  ): any {
    const formData: FormData = new FormData();
    let headers = new HttpHeaders()
      .set("Authorization", "Bearer " + User.getCurrentUser().token)
      .set("Access-Control-Allow-Origin", "*");
    fileItems.forEach((file) => {
      formData.append("files", file, file.name);
    });

    if (extraData) {
      for (let key in extraData) {
        formData.append(key, extraData[key]);
      }
    }
    const req = new HttpRequest("POST", this.g2glink + type, formData, {
      headers: headers,
      reportProgress: true, // for progress data
    });
    // return this.http.request(req).pipe(timeout(30000), map(response => {
    //     if (response && response['error'] && response['typee'] == '333') {
    //         localStorage.removeItem('sm_user');
    //         this.router.navigate(['/login']);
    //     }
    //     return response;
    // }));
    return this.http.request(req);
  }

  static getSigInit() {
    return environment.host + "sigcom/init";
  }

  private jsonToURLEncoded(jsonString) {
    return Object.keys(jsonString)
      .map(function (key) {
        return (
          encodeURIComponent(key) + "=" + encodeURIComponent(jsonString[key])
        );
      })
      .join("&");
  }
  private checkAuth() {
    if (!User.getCurrentUser()) {
      this.router.navigate(["/login"]);
    }
  }

  getUserDetails() {
    return JSON.parse(localStorage.getItem("ds_int"));
  }

  //   addSignature(payload) {
  //     const headers = new HttpHeaders().set(
  //       "Authorization",
  //       "Bearer " + User.getCurrentUser().token
  //     );
  //     let formData: FormData = new FormData();
  //     Object.entries(payload).forEach((o: any) => formData.append(o[0], o[1]));
  //     return this.http.post(
  //       `${environment.host}${NetworkService.CHANGE_PROFILE_SIGNATURE}`,
  //       formData,
  //       { headers }
  //     );
  //   }

  addSignature(payload): any {
    this.checkAuth();
    const formData: FormData = new FormData();
    let headers = new HttpHeaders()
      .set("Authorization", "Bearer " + User.getCurrentUser().token)
      .set("Access-Control-Allow-Origin", "*");
    formData.append("attachment", payload.Attachment);
    //   return this.http.post(`${environment.host}${NetworkService.CHANGE_PROFILE_SIGNATURE}`, formData, {headers});
    return this.http.post(`${this.link}int/upload-signature`, formData, {
      headers,
    });
  }

  // Everything Archiving Starts Here

  getAllFolders(): any {
    return this.http.get(
      `${environment.host}${NetworkService.GET_ALL_FOLDERS_IN_ARCHIVE}`
    );
  }

  createNewArchiveFolder(payload): any {
    // return this.http.post(
    //   `${environment.host}${NetworkService.GET_ALL_FOLDERS_IN_ARCHIVE}`,
    //   payload
    // );
    return this.sendRequestArchive(
      payload,
      `${NetworkService.GET_ALL_FOLDERS_IN_ARCHIVE}`
    );
  }

  renameArchiveFolder(payload): any {
    return this.http.post(
      `${environment.host}${NetworkService.GET_ALL_FOLDERS_IN_ARCHIVE}/Rename`,
      payload
    );
  }

  getSingleFolderContent(id: number): any {
    return this.http.get(
      `${environment.host}${NetworkService.GET_ALL_FOLDERS_IN_ARCHIVE}/${id}`
    );
  }

  deleteSingleFolderContent(id: number): any {
    return this.http.delete(
      `${environment.host}${NetworkService.GET_ALL_FOLDERS_IN_ARCHIVE}/${id}`
    );
  }

  addMetaDataToFolder(payload) {
    return this.http.post(
      `${environment.host}${NetworkService.ADD_METADATA_TO_FOLDERS_IN_ARCHIVE}`,
      payload
    );
  }

  getFolderMetaData(id: number) {
    return this.http.get(
      `${environment.host}${NetworkService.GET_METADATA_IN_FOLDERS_IN_ARCHIVE}/${id}`
    );
  }

  editMetaDataOnFolder(payload) {
    return this.http.post(
      `${environment.host}${NetworkService.EDIT_METADATA_TO_FOLDERS_IN_ARCHIVE}`,
      payload
    );
  }

  deleteMetaDataOnFolder(id) {
    return this.http.post(
      `${environment.host}${NetworkService.DELETE_METADATA_TO_FOLDERS_IN_ARCHIVE}/${id}`,
      {}
    );
  }

  handleSingleFileUploadWithProgressReport(
    payload
  ): Observable<HttpEvent<any>> {
    const headers = new HttpHeaders()
      .set("Authorization", "Bearer " + User.getCurrentUser().token)
      .set("Access-Control-Allow-Origin", "*");
    const formData: FormData = new FormData();
    console.log(payload);

    payload.Files.forEach((file) => {
      formData.append("Files", file, file.name);
    });
    // for (let index = 0; index < payload.MetadataIdAndValues.length; index++) {
    //   const element = payload.MetadataIdAndValues[index];
    //   formData.append(
    //     `MetadataIdAndValues[${index}][MetadataId]`,
    //     element.MetadataId
    //   );
    //   formData.append(`MetadataIdAndValues[${index}][Value]`, element.Value);
    // }
    formData.append(
      "MetadataIdAndValues",
      JSON.stringify(payload.MetadataIdAndValues)
    );
    for (let key in payload) {
      if (key === "Files" || key === "MetadataIdAndValues") {
      } else {
        formData.append(key, payload[key]);
      }
    }

    const req = new HttpRequest(
      "POST",
      `${environment.host}${NetworkService.UPLOAD_FILES_IN_FOLDERS_IN_ARCHIVE}`,
      formData,
      {
        reportProgress: true,
        responseType: "json",
        headers,
      }
    );

    return this.http.request(req);
  }
}
